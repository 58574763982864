/*!  
 ##Device = Laptops, Desktops
 ##Screen = B/w 1281px to 1680px

@media (min-width: 1281px) and (max-width: 1680px)
  .displayOnDesktop
    visibility: visible
  .hideOnDesktop
    visibility: hidden
    display: none
  .plr-10r
    padding-left: 5rem!important
    padding-right: 5rem!important
  .pl-20r
    padding-left: 2rem
  .plr-15r
    padding-left: 5rem!important
    padding-right: 5rem!important
  .w-20
    width: 25%
  .w-60
    width: 40%
  .absoluteVideoTitle
    left: 0rem
    top: 5rem
    padding: 1rem 2rem
    h2
      font-size: 4rem
    h3
      font-size: 3rem
      line-height: 3rem
  .absoluteHeaderTitle
    left: 0rem
  #brodcaster_pic
    left: -6rem
  .outerLeft
    left: -5rem
  .fixedBroadcasterCameraHeight
    height: 200px
  .coverImage
    img
      height: 200px
  .darkCover
    height: 200px
  .darkCover
    .play
      margin-right: 0rem
  .accountCameras
    .darkCover
      height: 250px

  #overlayNest
    position: absolute
    right: 0rem
    top: 28rem
    width: 7rem
    img
      max-width: 10rem
@media (min-width: 1300px) and (max-width: 1680px)
  .coverImage
    img
      height: 350px
  .darkCover
    height: 350px
@media (min-width: 1681px) and (max-width: 1920px)
    #overlayNest
        position: absolute
        right: 0rem
        top: 29rem
        width: 7rem
        img
            max-width: 10rem