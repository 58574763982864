/*! 
 ##Device = Tablets, Ipads (portrait)
 ##Screen = B/w 768px to 1100px
@media (min-width: 768px) and (max-width: 1100px)
  #pubBottom
    margin-top: 28rem
  .displayOnTablet
    visibility: visible
  .hideOnTablet
    visibility: hidden
    display: none
  .mAround
    display: flex
    justify-content: space-around
  .mm-0
    margin: 0px
  .mmt-2
    margin-top: 2rem
  .mmt-1
    margin-top: 1rem
  .mmt-5
    margin-top: 5rem
  .mml-2
    margin-left: 2rem
  .mplr-1
    padding-left: 1rem!important
    padding-right: 1rem!important
  .mp-1
    padding: 1rem!important
  .mpl-5
    padding-left: 3rem!important
  .mpr-5
    padding-right: 3rem!important
  .mp-05
    padding: 0.5rem!important
  .mp-0
    padding: 0rem!important
  #intro
    h2
      line-height: 1
  #introCamera
    max-width: 100%
    z-index: 2
  #txtCamera
    padding: 1rem
    z-index: 1
    background: #fff
  #txtNews
    position: inherit
    margin-top: 0
    left: 0
    h2
      font-size: 2rem
  .largeOuterRight
    position: inherit
    right: unset
  .outerRight
    position: inherit
    right: unset
  .outerLeft
    position: inherit
    left: unset
    margin-top: 1rem
  .navbar-toggler
    outline: none
    border: none
    color: #fff
    &:focus
      outline: none
      &:hover
        outline: none
  .btn-header
    padding: 0.5rem
  .mmw-100
    max-width: 100%
  .absoluteHeaderTitle
    margin-top: 1rem
    position: inherit
    padding: 1rem 3rem
    border-radius: 20px
    left: inherit
    top: inherit
    h1,h2
      font-size: 3rem
  .smallDarkBtn
    padding: 1rem 3rem
  #messageBox
    position: absolute
    left: 0
    top: -3rem
    height: 2rem
    max-width: 100%
  .chatbox
    display: inline
    font-size: 1rem
  #brodcaster_pic
    top: -5rem
    position: inherit
    left: 2rem
  .banner
    min-height: 5rem
  .fixedCameraHeight
    height: 25rem
  .coverImage
    img
      height: 25rem
      left: 0
      width: 100%
  .darkCover
    height: 25rem
  .darkCover
    left: 0
    width: 100%
  .detailCamera
    .coverImage
      width: 100%
      img
        height: 18rem
        max-width: 95%
    .darkCover
      width: 95%
      height: 18rem
      img
        max-width: 50%
        margin-top: 3rem
  .decoration
    margin-left: -2rem
  input
    max-width: 100%
  .navbar-toggler
    outline: none
    border: none
    color: #fff
    &:focus
      outline: none
      &:hover
        outline: none
  .broadcasterCoverImage
    background-size: cover
    background-repeat: no-repeat
    background-position-x: center
  .fixedBroadcasterCameraHeight
    height: 18rem
  .accountCameras
    .coverImage
      img
        height: 20rem
  .fixedAccountCameraHeight
    height: 25rem

  .rightOnTablet
    text-align: right
  .plr-15r
    padding-left: 5rem!important
    padding-right: 5rem!important
  .darkBtn
    padding: 1rem 3rem
  .absoluteVideoTitle
    left: -0.5rem
    top: 2rem
    padding: 1rem 2rem
    h2
      font-size: 3rem
    h3
      font-size: 2rem
      line-height: 2rem
  .absoluteVideoTitleMobile
    background-color: rgba($darkgrey,0.7)
    text-align: left
    padding: 1rem 2rem
    border-radius: 20px
    h1
      color: $brand-primary
      font-size: 3rem
      font-family: 'Nerko One', cursive
    h2
      color: $brand-primary
      font-size: 3rem
      font-family: 'Nerko One', cursive
    h3
      color: white
      font-size: 2.5rem
      line-height: 2.5rem
      font-family: 'Nerko One', cursive
    a
      color: white
      text-decoration: none
/*! 
 ##Device = Tablets, Ipads (landscape)
 ##Screen = B/w 768px to 1100px

@media (min-width: 768px) and (max-width: 1100px) and (orientation: landscape)
  .displayOnTablet
    visibility: visible!important
    display: flex!important
  .hideOnTablet
    visibility: hidden
    display: none
  .outerLeft
    left: 0rem
  .fixedCameraHeight
    height: 20rem
  .coverImage
    img
      height: 20rem
  .darkCover
    height: 20rem
  .coverImage
    img
      width: 100%
  .darkCover
    width: 100%
  .detailCamera
    .coverImage
      width: 100%
      img
        height: 300px
    .darkCover
      width: 95%
      height: 75%
      img
        max-width: 50%!important
        margin-top: 1rem!important
  .decoration
    margin-left: -3rem
  .largeOuterRight
    position: inherit
    right: 0rem
  .mlr-15
    margin-left: 5rem
  .mrr-15
    margin-right: 5rem
  .plr-10r
    padding-left: 1rem!important
    padding-right: 1rem!important
  .plr-15r
    padding-left: 5rem!important
    padding-right: 5rem!important
  .accountCameras
    .coverImage
      img
        height: 15rem
  .fixedAccountCameraHeight
    height: 15rem
  .playIcon
    padding-bottom: 15%
/*! 
 ##Device = Tablets, Ipads PRO (landscape)
 ##Screen = B/w 990px to 1100px

@media (min-width: 990px) and (max-width: 1100px)
  #brodcaster_pic
    left: 5%

  .detailCamera
    .coverImage
      width: 100%
      img
        height: 200px
    .darkCover
      width: 95%
      height: 100%
      img
        max-width: 50%
        margin-top: 3rem
  .fixedBroadcasterCameraHeight
    height: 200px
  .largeOuterRight
    position: inherit
    right: -28rem
  .outerRight
    position: inherit
    right: unset
  .outerLeft
    position: inherit
    left: 2rem
    margin-top: 1rem
