html
    overflow-x: hidden
body
    font-family: 'Montserrat', sans-serif
    font-size: 1.2rem
.footer
    width: 100%
    height: 60px
    position: inherit
    bottom: 0
    .nav-link
        color: white
        text-transform: capitalise
        font-weight: 400
        padding: 0.2rem
        font-size: 1rem
    img
        max-width: 50px
        vertical-align: middle
.text-nerko
    font-family: Nerko One,cursive
.video
    position: relative
    max-height: 300px
    iframe
        max-height: 100%
.coverImage
    position: absolute
    width: 100%
    left: 0rem
    top: 3rem
    img
        border-radius: 3rem
        position: absolute
        top: -2rem
        left: -1rem
        height: 350px
        width: 110%
.broadcasterCoverImage
    @extend .shadowed
    width: 100%
    height: 300px
    border-radius: 3rem
    background-size: cover
.broadcasterCompanyName
    font-size: 1.2rem
    font-weight: bold
.darkCover
    position: absolute
    left: -1rem
    top: 1rem
    height: 100%
    width: 110%
    border-radius: 3rem
    display: flex
    flex-direction: row
    text-align: center
    text-transform: uppercase
    font-family: Nerko One,cursive
    color: #fff
    padding: 2rem
    background-color: rgba(0,0,0,0.7)
    transition: opacity 1s
    opacity: 0
    justify-content: center
    flex-flow: wrap-reverse
    img
        border-radius: 3rem
        margin-right: 1rem
    span
        align-self: baseline
    .play
        width: 5rem
    .broadcasterLine
        position: absolute
        bottom: 2%
        width: 100%
    &:hover
        opacity: 1
.contactCamera
    .coverImage
        width: 85%
        img
            height: 170px
    .darkCover
        width: 94%
        height: 68%
        img
            max-width: 100%
            margin: 0
        .playIcon
            padding-bottom: 0%
            padding-top: 3rem
    .broadcasterName
        position: absolute
        bottom: 4%
.detailCamera
    .coverImage
        width: 85%
        img
            height: 200px
    .darkCover
        width: 94%
        height: 200px
        padding: 0rem
        img
            max-width: 25%
        .playIcon
            padding-bottom: 0%
        .broadcasterName
            margin-top: 3rem
.aboutCamera
    .darkCover
        height: 140%
.bordered
    border: solid 1px black
.shadowed
    box-shadow: 6px 4px 13px -5px rgba(0,0,0,0.46)
.headerBlock
    display: flex
    min-height: 6rem
    max-height: 6rem
    margin-top: -1rem
    div,button,a
        align-self: center
#mainMenu
    display: flex
    align-self: self-start
    height: -webkit-fill-available
#topbar
    a
        font-size: 1rem
        color: #ffffff!important
        font-weight: 600
.start
    .nav-item
        display: flex
        justify-content: center
        flex-direction: column
    .nav-link
        font-size: 1rem
        color: #ffffff!important
        font-weight: 600
.center
    .nav-link
        font-size: 1rem
        color: #ffffff!important
        font-weight: 600

header
    top: 0px
#content
    width: 100%
.absoluteVideoTitle
    background-color: rgba($darkgrey,0.7)
    text-align: left
    padding: 1rem 4rem
    position: absolute
    left: -2rem
    top: 5rem
    border-radius: 20px
    z-index: 2000
    h1
        color: $brand-primary
        font-size: 5rem
        font-family: 'Nerko One', cursive
    h2
        color: $brand-primary
        font-size: 5rem
        font-family: 'Nerko One', cursive
    h3
        color: white
        font-size: 65px
        line-height: 3rem
        font-family: 'Nerko One', cursive
    a
        color: white
        text-decoration: none
.absoluteHeaderTitle
    background-color: $darkgrey
    text-align: left
    padding: 1rem 4rem
    position: absolute
    left: -2rem
    top: 5rem
    border-radius: 20px
    h2,h1
        color: $brand-primary
        font-size: 4rem
        font-family: 'Nerko One', cursive
    h3
        color: white
        font-size: 1.5rem
        line-height: 3rem
        font-family: 'Nerko One', cursive
    a
        color: white
        text-decoration: none
video
    border-radius: 30px
#intro
    h2
        margin-top: 1rem
        font-size: 3rem
        font-weight: 300
        text-transform: uppercase
        line-height: 0.1
    h3
        color: $brand-primary
        font-size: 3rem
        text-transform: uppercase
        font-family: 'Nerko One', cursive

#introCamera
    background-color: $brand-primary
    color: white
    text-align: left
    padding: 2rem 3.5rem
    border-radius: 20px
    text-transform: uppercase
    max-width: 85%
    h2
        font-size: 2.5rem
    h3
        font-size: 1.5rem
        font-weight: 400
#txtCamera
    border: solid 4px $brand-primary
    border-radius: 20px
    padding: 10rem
    z-index: -1
    h2
        color: $brand-primary
        text-transform: uppercase
        font-size: 3rem
        font-family: 'Nerko One', cursive
        line-height: 4rem

#txtNews
    background-color: $brand-primary-dark
    color: white
    text-align: left
    padding: 1rem
    border-radius: 20px
    text-transform: uppercase
    max-width: 100%
    position: absolute
    margin-top: 5rem
    left: -9rem
    h2
        font-size: 2.5rem
    h3
        font-weight: 400
        font-size: 1.5rem

.row
    max-width: 100%
.blueBorder
    background-color: #fff
    border: solid $brand-primary 5px
    color: $brand-primary!important
    .nav-link
        text-align: left!important
        color: $brand-primary!important
        padding: 0rem
        font-size: 1rem
.outerRight
    position: inherit
    right: -10rem
    width: 100%
.largeOuterRight
    position: inherit
    right: -30rem
    width: 100%
.aboutOuterRight
    position: inherit
    right: -10rem
    width: 100%
    margin-bottom: 10rem
.aboutOuterLeft
    position: inherit
    left: -15rem
    width: 100%
    margin-bottom: 8rem

    .coverImage
        width: 80%
    .darkCover
        width: 88%
.outerLeft
    position: inherit
    left: -10rem
    width: 100%
    margin-top: 10rem

.decoration
    position: relative
    top: 4rem
    margin-left: -4rem
    z-index: 2000
    img
        max-width: 100px
a.nav-link:not(.nopaw):not(.btn-header):hover
    position: relative

a.nav-link:not(.nopaw):not(.btn-header):hover:before
    content: url("../../../images/hover.png")
    display: block
    position: absolute
    z-index: 1000
    left: -0.7rem
    top: -0.3rem
.btnIcon
    max-width: 5rem
    max-height: 2rem
.footerText,.footerText a
    text-align: left!important
    color: $brand-primary!important
    font-size: 1rem
.h-100
    height: 100%
.playIcon
    padding-bottom: 30%
    padding-top: 20%
    display: flex
    height: 100
    flex-direction: column
    justify-content: center
.hiden_camera
    display: none
#txtMission,#txtValues,#txtAbout
    h3
        font-size: 35px
        text-transform: uppercase
    h2
        font-family: 'Nerko One', cursive
        text-transform: uppercase
        font-size: 65px
#txtMission,#txtValues
    h2
        color: $brand-primary
#txtAbout
    color: white
#txtValues,#txtAbout
    text-align: center
#txtContact
    color: black!important
    padding: 3rem
#dynamic_form1
    display: flex
    justify-content: space-between
    flex-direction: column
    label
        width: 100%
        text-align: left
        margin-top: 0.5rem
        font-family: 'Nerko One', cursive
        font-size: 20px
        font-weight: 400
    input[type="text"],input[type="email"],textarea
        border-radius: 15px
        border: solid 1px black
        outline: none
        padding: 0.5rem
    button
        margin-top: 0.5rem
        padding: 5px 10px
        background-color: $brand-primary
        color: #fff
        text-transform: uppercase
        border: none
        outline: none
        box-shadow: inset 0 -0.6em 0 -0.35em rgba($darkgrey,0.17)
        font-family: 'Nerko One', cursive
        &:hover
            box-shadow: inset 0 -0.4em 0 -0.29em rgba($darkgrey,0.17)
.filterInput
    margin: 0.5rem 3rem 0rem 3rem

.filterLabel
    margin: 0rem
    padding: 0rem
    font-weight: 500
.filterTitle
    font-size: 1.1rem
    font-weight: bold
#contactCameraTitle
    color: #85c6d0
    font-size: 2rem
    text-align: center
    font-family: Nerko One,cursive
    text-transform: uppercase
.inFront
    z-index: 2
#aboutVideo
    position: absolute
    top: -15rem
    left: -3rem
.checkbox
    display: block
    position: relative
    padding-left: 35px
    margin-bottom: 12px
    cursor: pointer
    font-size: 1.3rem
    input
        position: absolute
        opacity: 0
        cursor: pointer
        height: 100%
        width: 100%
        left: 0rem
        margin: 0
        z-index: 2000
    .checkmark
        position: absolute
        top: 0.25rem
        left: 1rem
        height: 1.5rem
        width: 1.5rem
        background-color: #eee

    &:hover input ~ .checkmark
        background-color: #ccc
    input:checked ~ .checkmark
        background-color: $brand-primary-dark
    input:checked ~ .checkmark:after
        display: block

    .checkmark:after
        content: ""
        position: absolute
        display: none

    .checkmark:after
        left: 0.5rem
        top: 0.2rem
        width: 0.5rem
        height: 1rem
        border: solid white
        border-width: 0 3px 3px 0
        -webkit-transform: rotate(45deg)
        -ms-transform: rotate(45deg)
        transform: rotate(45deg)
#chat
    @extend .shadowed
    @extend .roundCorner
    height: 25rem
    overflow-y: scroll
    padding: 0.5rem
    background-color: $lightGrey
    -ms-overflow-style: none
    scrollbar-width: none
    .message
        margin-top: 1rem
        margin-bottom: 1rem
        img
            border-radius: 3rem
            max-width: 2.5rem
        .broadCasterName
            font-weight: 500
            font-family: Nerko One,cursive
            cursor: pointer
            margin-left: 1rem
#chat::-webkit-scrollbar
    display: none
#messageBox
    position: absolute
    right: -8rem
    top: 3rem
    height: inherit

.chatbox
    padding: 0.5rem 1rem
    background-color: $darkgrey
    color: #fff
    text-transform: uppercase
    cursor: pointer
    margin-top: 0.5rem
    border-radius: 3rem
    width: 100%
    transition: width 1s
    &:hover
        background-color: $brand-primary-dark
        width: 110%
.activeBox
    background-color: $brand-primary-dark
    width: 110%
#messageType
    background: $brand-primary
    padding: 0.2rem 0.5rem
    text-transform: uppercase
    color: white
    border-radius: 3rem
#chatControls
    margin-top: 2rem
    #sendBtn
        @extend .smallDarkBtn
        padding: 0.1rem
        width: 100%
        outline: none
        border: solid 1px white
        span
            display: inline-flex
            font-size: 1rem
        .btnIcon
            max-width: 3rem
        &:hover
            background-color: $brand-primary-dark
            border-color: white
    #message,#name
        @extend .shadowed
        @extend .roundCorner
        width: 100%
        border: none
        outline: none
        padding: 0.5rem 1rem
        background-color: $lightGrey

#brodcaster_pic
    position: absolute
    top: -4rem
    left: 4rem
    box-shadow: 1rem 1rem 1rem -1rem rgba(0,0,0,0.7)
    border-radius: 6.25rem!important
#broadcaster_occupation
    background-color: $darkgrey
    color: white
    padding: 0.3rem .5rem
    width: fit-content
    border-radius: 0.25rem
    font-size: 1rem
.stylisedTitle
    color: $brand-primary
    text-transform: uppercase
    font-family: 'Nerko One', cursive
#socialBlock a
    padding: 0.5rem
    background-color: $darkgrey
    margin-right: 1rem
    border-radius: 3rem
    color: white
    display: inline-flex
    &:hover
        background-color: $brand-primary-dark
        color: $darkgrey
        text-decoration: none
.camera
    position: relative
    .infos
        font-family: 'Nerko One', cursive
.banner
    width: 100%
#formLogin
    border: 4px solid #85c6d0
    border-radius: 20px
    padding: 2rem
    z-index: -1
    font-size: 1rem
.zoom
    transition: transform 1s
    &:hover
        transform: scale(1.1)
.input-group-text
    background-color: $darkgrey
    color: white
.accountCameras
    background-color: $brand-primary-dark
    padding: 1rem
    color: white
    border-radius: 3rem
    .coverImage
        position: absolute
        width: 100%
        left: 0rem
        top: 3rem
        img
            border-radius: 3rem
            position: absolute
            top: -2rem
            left: 0rem
            height: 250px
            width: 100%
    .darkCover
        width: 100%
        left: 0rem
    .cameraHeader
        padding: 0.5rem
        border-top: solid 3px white
        border-bottom: solid 3px white
        margin: 1rem 0rem 1rem 0rem
        h3
            margin: 0
        a
            color: white
            cursor: pointer
            &:hover
                color: white
                text-decoration: none
.profileForm
    input,select,textarea
        width: 100%
        padding: 0.5rem
        border-radius: 3rem
        outline: none
#cameraModal
    input,select,textarea
        width: 100%
        padding: 0.5rem
        border-radius: 3rem
        outline: none
#addCameraModal
    input,select,textarea
        width: 100%
        padding: 0.5rem
        border-radius: 3rem
        outline: none
    #profile
        div
            margin-top: 0.5rem
            margin-bottom: 0.5rem
        label
            display: block
    #profile_formOfAddress
        input
            display: inline-flex
            max-width: 30%
        label
            display: inline-flex
            max-width: 80%
.banner
    .imgBanner
        width: 100%
        height: 8rem
#aboutPage
    .inFront
        z-index: -1
.fixedBroadcasterCameraHeight
    height: 200px
.fixedCameraHeight
    height: 250px
.fixedAccountCameraHeight
    height: 250px
.active
    text-decoration: underline
.formError
    ul
        list-style: none
        padding: inherit
        color: red
.errorMessage
    h5
        text-align: center
        padding: 2rem
        background-color: #e84848
        color: white
        text-transform: uppercase
        font-family: Nerko One,cursive
        border-radius: 3rem
.errorMessageLittle
    h5
        text-align: center
        padding: 1rem
        background-color: #e84848
        color: white
        text-transform: uppercase
        font-family: Nerko One,cursive
        border-radius: 3rem
.successMessage
    h5
        text-align: center
        padding: 2rem
        background-color: #85c6d0
        color: #505955
        text-transform: uppercase
        font-family: Nerko One,cursive
        border-radius: 3rem
.successMessageLittle
    h5
        text-align: center
        padding: 1rem
        background-color: #85c6d0
        color: #505955
        text-transform: uppercase
        font-family: Nerko One,cursive
        border-radius: 3rem
#overlayNest
    display: none!important
#languageSwitcher
    display: flex
    width: 20%
    justify-content: flex-end
.language_switcher
    cursor: pointer
    &:hover
        color: #505955
#pubBottom
    position: absolute
    margin-top: 12rem
    max-height: 0rem
    img
        max-height: 300px