.darkBg {
  background-color: #525955;
  color: white;
}

.blueBg {
  background-color: #75BFA0;
  color: white;
}

.darkBlueBg {
  background-color: #69AB90;
  color: white;
}

.whiteBg {
  background-color: #fff;
}

.blueBord {
  border: 4px solid #75BFA0;
  border-radius: 20px;
  z-index: -1;
}

.white {
  color: white;
}

@media (min-width: 1281px) {
  .mw-50 {
    max-width: 50%;
  }

  .mw-20 {
    max-width: 20%;
  }

  .mw-30 {
    max-width: 30%;
  }

  .mw-40 {
    max-width: 40%;
  }

  .w-20 {
    width: 20%;
  }

  .w-25 {
    width: 25%;
  }

  .w-45 {
    width: 45%;
  }

  .w-60 {
    width: 60%;
  }

  .w-95 {
    width: 95% !important;
  }

  .p-0 {
    padding: 0px;
  }

  .pr-1 {
    padding: 1rem;
  }

  .pr-2 {
    padding: 2rem;
  }

  .mlr-5 {
    margin-left: 5rem;
  }

  .mtr-5 {
    margin-top: 5rem;
  }

  .mtr-10 {
    margin-top: 10rem;
  }

  .mtr-15 {
    margin-top: 15rem;
  }

  .mbr-5 {
    margin-bottom: 5rem;
  }

  .mbr-8 {
    margin-bottom: 8rem;
  }

  .mbr-10 {
    margin-bottom: 10rem;
  }

  .mlr-10 {
    margin-left: 10rem;
  }

  .mrr-5 {
    margin-right: 5rem;
  }

  .mrr-10 {
    margin-right: 10rem;
  }

  .mlr-15 {
    margin-left: 15rem;
  }

  .mrr-15 {
    margin-right: 15rem;
  }

  .mrr-20 {
    margin-right: 20rem;
  }

  .mrr-25 {
    margin-right: 25rem;
  }

  .mh-10 {
    min-height: 10rem;
  }

  .mh-7 {
    min-height: 7rem;
  }

  .pl-20r {
    padding-left: 20rem;
  }

  .pl-25r {
    padding-left: 25rem;
  }

  .plr-5r {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .plr-1r {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .plr-10r {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .plr-15r {
    padding-left: 15rem !important;
    padding-right: 15rem !important;
  }

  .p-header {
    padding: 0.5rem 1rem;
  }

  .m-header {
    margin: 0.5rem 1rem;
  }
}
@media (min-width: 1023px) and (max-width: 1280px) {
  .mw-50 {
    max-width: 50%;
  }

  .mw-20 {
    max-width: 20%;
  }

  .mw-30 {
    max-width: 30%;
  }

  .mw-40 {
    max-width: 40%;
  }

  .w-20 {
    width: 20%;
  }

  .w-25 {
    width: 25%;
  }

  .w-45 {
    width: 45%;
  }

  .w-60 {
    width: 60%;
  }

  .w-95 {
    width: 95% !important;
  }

  .p-0 {
    padding: 0px;
  }

  .pr-1 {
    padding: 1rem;
  }

  .pr-2 {
    padding: 2rem;
  }

  .mlr-5 {
    margin-left: 5rem;
  }

  .mtr-5 {
    margin-top: 5rem;
  }

  .mtr-10 {
    margin-top: 5rem;
  }

  .mtr-15 {
    margin-top: 15rem;
  }

  .mbr-5 {
    margin-bottom: 5rem;
  }

  .mbr-8 {
    margin-bottom: 8rem;
  }

  .mbr-10 {
    margin-bottom: 5rem;
  }

  .mlr-10 {
    margin-left: 10rem;
  }

  .mrr-5 {
    margin-right: 5rem;
  }

  .mrr-10 {
    margin-right: 10rem;
  }

  .mrr-20 {
    margin-right: 20rem;
  }

  .mrr-25 {
    margin-right: 25rem;
  }

  .mh-10 {
    min-height: 10rem;
  }

  .mh-7 {
    min-height: 7rem;
  }

  .pl-20r {
    padding-left: 20rem;
  }

  .pl-25r {
    padding-left: 25rem;
  }

  .plr-5r {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .plr-1r {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .plr-10r {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .plr-15r {
    padding-left: 15rem !important;
    padding-right: 15rem !important;
  }

  .p-header {
    padding: 0.5rem 1rem;
  }

  .m-header {
    margin: 0.5rem 1rem;
  }
}
.w-15r {
  width: 15rem;
}

.fdc {
  flex-direction: column;
}

.jcsb {
  justify-content: space-between;
}

.round {
  border-radius: 0px 0px 20px 20px;
}

.roundCorner, #chatControls #message, #chatControls #name, #chat {
  border-radius: 20px;
}

.br-2r {
  border-radius: 2rem;
}

.br-3r {
  border-radius: 3rem;
}

.end {
  display: flex;
  margin-left: auto;
}

.nobord {
  border: none;
}

.flexCollumnCenter {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.flexTextEnd {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.f-1 {
  font-size: 1rem;
}

.l-0 {
  left: 0px !important;
}

.f-w-normal {
  font-weight: 200 !important;
}

#pubBottom .carousel-indicators {
  display: none;
}

#hauts .carousel-indicators {
  display: none;
}

#droits .carousel-indicators {
  display: none;
}

.btn-header {
  background-color: white;
  color: black !important;
  border-radius: 10px;
  padding: 5px 10px;
  font-weight: 600;
}

.darkBtn {
  background-color: #525955;
  color: #fff !important;
  padding: 1rem 12rem;
  border-radius: 14px;
  font-weight: 500;
  font-size: 1rem;
  justify-content: center;
  text-transform: uppercase;
}
.darkBtn span {
  vertical-align: middle;
}
.darkBtn:hover {
  text-decoration: none !important;
}

.smallDarkBtn, #chatControls #sendBtn {
  background-color: #525955;
  color: #fff !important;
  padding: 1rem 5rem;
  border-radius: 14px;
  font-weight: 500;
  font-size: 1rem;
  justify-content: center;
  text-transform: uppercase;
}
.smallDarkBtn span, #chatControls #sendBtn span {
  vertical-align: middle;
}
.smallDarkBtn:hover, #chatControls #sendBtn:hover {
  text-decoration: none !important;
}

.btnAddikpetDark {
  margin-top: 0.5rem;
  padding: 5px 10px;
  background-color: #525955;
  color: #fff;
  text-transform: uppercase;
  border: none;
  outline: none;
  font-size: 1rem;
  box-shadow: inset 0 -0.6em 0 -0.35em #75bfa0;
  font-family: Nerko One, cursive;
}
.btnAddikpetDark:hover {
  color: #75BFA0;
  text-decoration: none !important;
  box-shadow: inset 0 -0.4em 0 -0.29em #75bfa0;
}

.btnAddikpet {
  margin-top: 0.5rem;
  padding: 5px 10px;
  background-color: #75BFA0;
  color: #fff;
  text-transform: uppercase;
  border: none;
  outline: none;
  box-shadow: inset 0 -0.6em 0 -0.35em rgba(82, 89, 85, 0.17);
  font-family: "Nerko One", cursive;
}
.btnAddikpet:hover {
  box-shadow: inset 0 -0.4em 0 -0.29em rgba(82, 89, 85, 0.17);
}

.btnSub {
  padding: 0.3rem 0.5rem;
}

html {
  overflow-x: hidden;
}

body {
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
}

.footer {
  width: 100%;
  height: 60px;
  position: inherit;
  bottom: 0;
}
.footer .nav-link {
  color: white;
  text-transform: capitalise;
  font-weight: 400;
  padding: 0.2rem;
  font-size: 1rem;
}
.footer img {
  max-width: 50px;
  vertical-align: middle;
}

.text-nerko {
  font-family: Nerko One, cursive;
}

.video {
  position: relative;
  max-height: 300px;
}
.video iframe {
  max-height: 100%;
}

.coverImage {
  position: absolute;
  width: 100%;
  left: 0rem;
  top: 3rem;
}
.coverImage img {
  border-radius: 3rem;
  position: absolute;
  top: -2rem;
  left: -1rem;
  height: 350px;
  width: 110%;
}

.broadcasterCoverImage {
  width: 100%;
  height: 300px;
  border-radius: 3rem;
  background-size: cover;
}

.broadcasterCompanyName {
  font-size: 1.2rem;
  font-weight: bold;
}

.darkCover {
  position: absolute;
  left: -1rem;
  top: 1rem;
  height: 100%;
  width: 110%;
  border-radius: 3rem;
  display: flex;
  flex-direction: row;
  text-align: center;
  text-transform: uppercase;
  font-family: Nerko One, cursive;
  color: #fff;
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.7);
  transition: opacity 1s;
  opacity: 0;
  justify-content: center;
  flex-flow: wrap-reverse;
}
.darkCover img {
  border-radius: 3rem;
  margin-right: 1rem;
}
.darkCover span {
  align-self: baseline;
}
.darkCover .play {
  width: 5rem;
}
.darkCover .broadcasterLine {
  position: absolute;
  bottom: 2%;
  width: 100%;
}
.darkCover:hover {
  opacity: 1;
}

.contactCamera .coverImage {
  width: 85%;
}
.contactCamera .coverImage img {
  height: 170px;
}
.contactCamera .darkCover {
  width: 94%;
  height: 68%;
}
.contactCamera .darkCover img {
  max-width: 100%;
  margin: 0;
}
.contactCamera .darkCover .playIcon {
  padding-bottom: 0%;
  padding-top: 3rem;
}
.contactCamera .broadcasterName {
  position: absolute;
  bottom: 4%;
}

.detailCamera .coverImage {
  width: 85%;
}
.detailCamera .coverImage img {
  height: 200px;
}
.detailCamera .darkCover {
  width: 94%;
  height: 200px;
  padding: 0rem;
}
.detailCamera .darkCover img {
  max-width: 25%;
}
.detailCamera .darkCover .playIcon {
  padding-bottom: 0%;
}
.detailCamera .darkCover .broadcasterName {
  margin-top: 3rem;
}

.aboutCamera .darkCover {
  height: 140%;
}

.bordered {
  border: solid 1px black;
}

.shadowed, #chatControls #message, #chatControls #name, #chat, .broadcasterCoverImage {
  box-shadow: 6px 4px 13px -5px rgba(0, 0, 0, 0.46);
}

.headerBlock {
  display: flex;
  min-height: 6rem;
  max-height: 6rem;
  margin-top: -1rem;
}
.headerBlock div, .headerBlock button, .headerBlock a {
  align-self: center;
}

#mainMenu {
  display: flex;
  align-self: self-start;
  height: -webkit-fill-available;
}

#topbar a {
  font-size: 1rem;
  color: #ffffff !important;
  font-weight: 600;
}

.start .nav-item {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.start .nav-link {
  font-size: 1rem;
  color: #ffffff !important;
  font-weight: 600;
}

.center .nav-link {
  font-size: 1rem;
  color: #ffffff !important;
  font-weight: 600;
}

header {
  top: 0px;
}

#content {
  width: 100%;
}

.absoluteVideoTitle {
  background-color: rgba(82, 89, 85, 0.7);
  text-align: left;
  padding: 1rem 4rem;
  position: absolute;
  left: -2rem;
  top: 5rem;
  border-radius: 20px;
  z-index: 2000;
}
.absoluteVideoTitle h1 {
  color: #75BFA0;
  font-size: 5rem;
  font-family: "Nerko One", cursive;
}
.absoluteVideoTitle h2 {
  color: #75BFA0;
  font-size: 5rem;
  font-family: "Nerko One", cursive;
}
.absoluteVideoTitle h3 {
  color: white;
  font-size: 65px;
  line-height: 3rem;
  font-family: "Nerko One", cursive;
}
.absoluteVideoTitle a {
  color: white;
  text-decoration: none;
}

.absoluteHeaderTitle {
  background-color: #525955;
  text-align: left;
  padding: 1rem 4rem;
  position: absolute;
  left: -2rem;
  top: 5rem;
  border-radius: 20px;
}
.absoluteHeaderTitle h2, .absoluteHeaderTitle h1 {
  color: #75BFA0;
  font-size: 4rem;
  font-family: "Nerko One", cursive;
}
.absoluteHeaderTitle h3 {
  color: white;
  font-size: 1.5rem;
  line-height: 3rem;
  font-family: "Nerko One", cursive;
}
.absoluteHeaderTitle a {
  color: white;
  text-decoration: none;
}

video {
  border-radius: 30px;
}

#intro h2 {
  margin-top: 1rem;
  font-size: 3rem;
  font-weight: 300;
  text-transform: uppercase;
  line-height: 0.1;
}
#intro h3 {
  color: #75BFA0;
  font-size: 3rem;
  text-transform: uppercase;
  font-family: "Nerko One", cursive;
}

#introCamera {
  background-color: #75BFA0;
  color: white;
  text-align: left;
  padding: 2rem 3.5rem;
  border-radius: 20px;
  text-transform: uppercase;
  max-width: 85%;
}
#introCamera h2 {
  font-size: 2.5rem;
}
#introCamera h3 {
  font-size: 1.5rem;
  font-weight: 400;
}

#txtCamera {
  border: solid 4px #75BFA0;
  border-radius: 20px;
  padding: 10rem;
  z-index: -1;
}
#txtCamera h2 {
  color: #75BFA0;
  text-transform: uppercase;
  font-size: 3rem;
  font-family: "Nerko One", cursive;
  line-height: 4rem;
}

#txtNews {
  background-color: #69AB90;
  color: white;
  text-align: left;
  padding: 1rem;
  border-radius: 20px;
  text-transform: uppercase;
  max-width: 100%;
  position: absolute;
  margin-top: 5rem;
  left: -9rem;
}
#txtNews h2 {
  font-size: 2.5rem;
}
#txtNews h3 {
  font-weight: 400;
  font-size: 1.5rem;
}

.row {
  max-width: 100%;
}

.blueBorder {
  background-color: #fff;
  border: solid #75BFA0 5px;
  color: #75BFA0 !important;
}
.blueBorder .nav-link {
  text-align: left !important;
  color: #75BFA0 !important;
  padding: 0rem;
  font-size: 1rem;
}

.outerRight {
  position: inherit;
  right: -10rem;
  width: 100%;
}

.largeOuterRight {
  position: inherit;
  right: -30rem;
  width: 100%;
}

.aboutOuterRight {
  position: inherit;
  right: -10rem;
  width: 100%;
  margin-bottom: 10rem;
}

.aboutOuterLeft {
  position: inherit;
  left: -15rem;
  width: 100%;
  margin-bottom: 8rem;
}
.aboutOuterLeft .coverImage {
  width: 80%;
}
.aboutOuterLeft .darkCover {
  width: 88%;
}

.outerLeft {
  position: inherit;
  left: -10rem;
  width: 100%;
  margin-top: 10rem;
}

.decoration {
  position: relative;
  top: 4rem;
  margin-left: -4rem;
  z-index: 2000;
}
.decoration img {
  max-width: 100px;
}

a.nav-link:not(.nopaw):not(.btn-header):hover {
  position: relative;
}

a.nav-link:not(.nopaw):not(.btn-header):hover:before {
  content: url("../../../images/hover.png");
  display: block;
  position: absolute;
  z-index: 1000;
  left: -0.7rem;
  top: -0.3rem;
}

.btnIcon {
  max-width: 5rem;
  max-height: 2rem;
}

.footerText, .footerText a {
  text-align: left !important;
  color: #75BFA0 !important;
  font-size: 1rem;
}

.h-100 {
  height: 100%;
}

.playIcon {
  padding-bottom: 30%;
  padding-top: 20%;
  display: flex;
  height: 100;
  flex-direction: column;
  justify-content: center;
}

.hiden_camera {
  display: none;
}

#txtMission h3, #txtValues h3, #txtAbout h3 {
  font-size: 35px;
  text-transform: uppercase;
}
#txtMission h2, #txtValues h2, #txtAbout h2 {
  font-family: "Nerko One", cursive;
  text-transform: uppercase;
  font-size: 65px;
}

#txtMission h2, #txtValues h2 {
  color: #75BFA0;
}

#txtAbout {
  color: white;
}

#txtValues, #txtAbout {
  text-align: center;
}

#txtContact {
  color: black !important;
  padding: 3rem;
}

#dynamic_form1 {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
#dynamic_form1 label {
  width: 100%;
  text-align: left;
  margin-top: 0.5rem;
  font-family: "Nerko One", cursive;
  font-size: 20px;
  font-weight: 400;
}
#dynamic_form1 input[type=text], #dynamic_form1 input[type=email], #dynamic_form1 textarea {
  border-radius: 15px;
  border: solid 1px black;
  outline: none;
  padding: 0.5rem;
}
#dynamic_form1 button {
  margin-top: 0.5rem;
  padding: 5px 10px;
  background-color: #75BFA0;
  color: #fff;
  text-transform: uppercase;
  border: none;
  outline: none;
  box-shadow: inset 0 -0.6em 0 -0.35em rgba(82, 89, 85, 0.17);
  font-family: "Nerko One", cursive;
}
#dynamic_form1 button:hover {
  box-shadow: inset 0 -0.4em 0 -0.29em rgba(82, 89, 85, 0.17);
}

.filterInput {
  margin: 0.5rem 3rem 0rem 3rem;
}

.filterLabel {
  margin: 0rem;
  padding: 0rem;
  font-weight: 500;
}

.filterTitle {
  font-size: 1.1rem;
  font-weight: bold;
}

#contactCameraTitle {
  color: #85c6d0;
  font-size: 2rem;
  text-align: center;
  font-family: Nerko One, cursive;
  text-transform: uppercase;
}

.inFront {
  z-index: 2;
}

#aboutVideo {
  position: absolute;
  top: -15rem;
  left: -3rem;
}

.checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 1.3rem;
}
.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  left: 0rem;
  margin: 0;
  z-index: 2000;
}
.checkbox .checkmark {
  position: absolute;
  top: 0.25rem;
  left: 1rem;
  height: 1.5rem;
  width: 1.5rem;
  background-color: #eee;
}
.checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}
.checkbox input:checked ~ .checkmark {
  background-color: #69AB90;
}
.checkbox input:checked ~ .checkmark:after {
  display: block;
}
.checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.checkbox .checkmark:after {
  left: 0.5rem;
  top: 0.2rem;
  width: 0.5rem;
  height: 1rem;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

#chat {
  height: 25rem;
  overflow-y: scroll;
  padding: 0.5rem;
  background-color: #f9f9f9;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
#chat .message {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
#chat .message img {
  border-radius: 3rem;
  max-width: 2.5rem;
}
#chat .message .broadCasterName {
  font-weight: 500;
  font-family: Nerko One, cursive;
  cursor: pointer;
  margin-left: 1rem;
}

#chat::-webkit-scrollbar {
  display: none;
}

#messageBox {
  position: absolute;
  right: -8rem;
  top: 3rem;
  height: inherit;
}

.chatbox {
  padding: 0.5rem 1rem;
  background-color: #525955;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 0.5rem;
  border-radius: 3rem;
  width: 100%;
  transition: width 1s;
}
.chatbox:hover {
  background-color: #69AB90;
  width: 110%;
}

.activeBox {
  background-color: #69AB90;
  width: 110%;
}

#messageType {
  background: #75BFA0;
  padding: 0.2rem 0.5rem;
  text-transform: uppercase;
  color: white;
  border-radius: 3rem;
}

#chatControls {
  margin-top: 2rem;
}
#chatControls #sendBtn {
  padding: 0.1rem;
  width: 100%;
  outline: none;
  border: solid 1px white;
}
#chatControls #sendBtn span {
  display: inline-flex;
  font-size: 1rem;
}
#chatControls #sendBtn .btnIcon {
  max-width: 3rem;
}
#chatControls #sendBtn:hover {
  background-color: #69AB90;
  border-color: white;
}
#chatControls #message, #chatControls #name {
  width: 100%;
  border: none;
  outline: none;
  padding: 0.5rem 1rem;
  background-color: #f9f9f9;
}

#brodcaster_pic {
  position: absolute;
  top: -4rem;
  left: 4rem;
  box-shadow: 1rem 1rem 1rem -1rem rgba(0, 0, 0, 0.7);
  border-radius: 6.25rem !important;
}

#broadcaster_occupation {
  background-color: #525955;
  color: white;
  padding: 0.3rem 0.5rem;
  width: fit-content;
  border-radius: 0.25rem;
  font-size: 1rem;
}

.stylisedTitle {
  color: #75BFA0;
  text-transform: uppercase;
  font-family: "Nerko One", cursive;
}

#socialBlock a {
  padding: 0.5rem;
  background-color: #525955;
  margin-right: 1rem;
  border-radius: 3rem;
  color: white;
  display: inline-flex;
}
#socialBlock a:hover {
  background-color: #69AB90;
  color: #525955;
  text-decoration: none;
}

.camera {
  position: relative;
}
.camera .infos {
  font-family: "Nerko One", cursive;
}

.banner {
  width: 100%;
}

#formLogin {
  border: 4px solid #85c6d0;
  border-radius: 20px;
  padding: 2rem;
  z-index: -1;
  font-size: 1rem;
}

.zoom {
  transition: transform 1s;
}
.zoom:hover {
  transform: scale(1.1);
}

.input-group-text {
  background-color: #525955;
  color: white;
}

.accountCameras {
  background-color: #69AB90;
  padding: 1rem;
  color: white;
  border-radius: 3rem;
}
.accountCameras .coverImage {
  position: absolute;
  width: 100%;
  left: 0rem;
  top: 3rem;
}
.accountCameras .coverImage img {
  border-radius: 3rem;
  position: absolute;
  top: -2rem;
  left: 0rem;
  height: 250px;
  width: 100%;
}
.accountCameras .darkCover {
  width: 100%;
  left: 0rem;
}
.accountCameras .cameraHeader {
  padding: 0.5rem;
  border-top: solid 3px white;
  border-bottom: solid 3px white;
  margin: 1rem 0rem 1rem 0rem;
}
.accountCameras .cameraHeader h3 {
  margin: 0;
}
.accountCameras .cameraHeader a {
  color: white;
  cursor: pointer;
}
.accountCameras .cameraHeader a:hover {
  color: white;
  text-decoration: none;
}

.profileForm input, .profileForm select, .profileForm textarea {
  width: 100%;
  padding: 0.5rem;
  border-radius: 3rem;
  outline: none;
}

#cameraModal input, #cameraModal select, #cameraModal textarea {
  width: 100%;
  padding: 0.5rem;
  border-radius: 3rem;
  outline: none;
}

#addCameraModal input, #addCameraModal select, #addCameraModal textarea {
  width: 100%;
  padding: 0.5rem;
  border-radius: 3rem;
  outline: none;
}
#addCameraModal #profile div {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
#addCameraModal #profile label {
  display: block;
}
#addCameraModal #profile_formOfAddress input {
  display: inline-flex;
  max-width: 30%;
}
#addCameraModal #profile_formOfAddress label {
  display: inline-flex;
  max-width: 80%;
}

.banner .imgBanner {
  width: 100%;
  height: 8rem;
}

#aboutPage .inFront {
  z-index: -1;
}

.fixedBroadcasterCameraHeight {
  height: 200px;
}

.fixedCameraHeight {
  height: 250px;
}

.fixedAccountCameraHeight {
  height: 250px;
}

.active {
  text-decoration: underline;
}

.formError ul {
  list-style: none;
  padding: inherit;
  color: red;
}

.errorMessage h5 {
  text-align: center;
  padding: 2rem;
  background-color: #e84848;
  color: white;
  text-transform: uppercase;
  font-family: Nerko One, cursive;
  border-radius: 3rem;
}

.errorMessageLittle h5 {
  text-align: center;
  padding: 1rem;
  background-color: #e84848;
  color: white;
  text-transform: uppercase;
  font-family: Nerko One, cursive;
  border-radius: 3rem;
}

.successMessage h5 {
  text-align: center;
  padding: 2rem;
  background-color: #85c6d0;
  color: #505955;
  text-transform: uppercase;
  font-family: Nerko One, cursive;
  border-radius: 3rem;
}

.successMessageLittle h5 {
  text-align: center;
  padding: 1rem;
  background-color: #85c6d0;
  color: #505955;
  text-transform: uppercase;
  font-family: Nerko One, cursive;
  border-radius: 3rem;
}

#overlayNest {
  display: none !important;
}

#languageSwitcher {
  display: flex;
  width: 20%;
  justify-content: flex-end;
}

.language_switcher {
  cursor: pointer;
}
.language_switcher:hover {
  color: #505955;
}

#pubBottom {
  position: absolute;
  margin-top: 12rem;
  max-height: 0rem;
}
#pubBottom img {
  max-height: 300px;
}

/** Light color-scheme **/
:root {
  --cc-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --cc-font-size: 16px;
  --cc-bg: #fff;
  --cc-z-index: 2147483647;
  --cc-text: #2b2b2b;
  --cc-border-radius: .45rem;
  --cc-btn-primary-bg: #75BFA0;
  --cc-btn-primary-text: var(--cc-bg);
  --cc-btn-primary-hover-bg: #525955;
  --cc-btn-primary-hover-text: var(--cc-btn-primary-text);
  --cc-btn-secondary-bg: #eaeff2;
  --cc-btn-secondary-text: var(--cc-text);
  --cc-btn-secondary-hover-bg: #d8e0e6;
  --cc-btn-secondary-hover-text: var(--cc-btn-secondary-text);
  --cc-btn-border-radius: 2em;
  --cc-toggle-bg-off: #919ea6;
  --cc-toggle-bg-on: var(--cc-btn-primary-bg);
  --cc-toggle-bg-readonly: #d5dee2;
  --cc-toggle-knob-bg: #fff;
  --cc-toggle-knob-icon-color: #ecf2fa;
  --cc-block-text: var(--cc-text);
  --cc-cookie-category-block-bg: #F7F7F7;
  --cc-cookie-category-block-bg-hover: #e9eff4;
  --cc-section-border: #f1f3f5;
  --cc-cookie-table-border: #e9edf2;
  --cc-overlay-bg: rgb(4, 6, 8);
  --cc-overlay-opacity: .85;
  --cc-consent-modal-box-shadow: 0 0.625rem 1.875rem rgba(2, 2, 3, 0.28);
  --cc-webkit-scrollbar-bg: #cfd5db;
  --cc-webkit-scrollbar-bg-hover: #9199a0;
}

button[data-cc=show-preferencesModal] {
  position: fixed;
  z-index: 9999;
  bottom: 0;
  left: 2rem;
  padding: 12px 18px;
  border: 0;
  border-radius: 8px 8px 0 0;
  background-color: white;
  cursor: pointer;
  transform: translateY(30px);
  transition: transform 0.25s ease;
  will-change: transform;
  font-size: 14px;
  font-weight: bold;
  color: black;
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.5);
}

button[data-cc=show-preferencesModal]:hover {
  transform: translateY(0);
}

button[data-cc=show-preferencesModal]:focus {
  outline: none;
}

/*! 
 * ##Device = Most of the Small Smartphones Mobiles (Portrait)
 * ##Screen = B/w 320px to 479px */
@media (max-width: 320px) {
  #pubBottom {
    margin-top: 28rem;
  }

  .displayOnMobile {
    visibility: visible;
  }

  .hideOnMobile {
    visibility: hidden;
    display: none;
  }

  .mAround {
    display: flex;
    justify-content: space-around;
  }

  .mm-0 {
    margin: 0px;
  }

  .mmt-2 {
    margin-top: 2rem;
  }

  .mmb-0 {
    margin-bottom: 0rem !important;
  }

  .mmt-0 {
    margin-top: 0rem !important;
  }

  .mmt-1 {
    margin-top: 1rem !important;
  }

  .mml-2 {
    margin-left: 2rem;
  }

  .mmt-5 {
    margin-top: 5rem;
  }

  .mplr-1 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .mp-1 {
    padding: 1rem !important;
  }

  .mp-05 {
    padding: 0.5rem !important;
  }

  .mp-0 {
    padding: 0rem !important;
  }

  .m-text-center {
    text-align: center;
  }

  .coverImage {
    position: absolute;
    width: 100%;
    left: 0rem;
    top: 3rem;
  }
  .coverImage img {
    border-radius: 3rem;
    position: absolute;
    top: -2rem;
    left: 0rem;
    height: 15rem;
    width: 100%;
  }

  .fixedCameraHeight {
    height: 15rem;
  }

  .darkCover {
    height: 71%;
    width: 100%;
    left: 0rem;
  }

  #intro h2 {
    line-height: 1;
    font-size: 2rem;
  }
  #intro h3 {
    font-size: 2rem;
  }

  #introCamera {
    padding: 1.5rem;
    max-width: 100%;
    z-index: 2;
  }
  #introCamera h2 {
    font-size: 2rem;
  }
  #introCamera h3 {
    font-size: 1rem;
  }

  #txtCamera {
    padding: 1rem;
    z-index: 1;
    background: #fff;
  }
  #txtCamera h2 {
    font-size: 2rem;
    line-height: 2rem;
  }

  #txtNews {
    position: inherit;
    margin-top: 0;
    left: 0;
  }
  #txtNews h2 {
    font-size: 2rem;
  }
  #txtNews h3 {
    font-size: 1rem;
  }

  #txtAbout h3, #txtMission h3, #txtValues h3 {
    font-size: 1.5rem;
  }

  #txtAbout h2, #txtMission h2, #txtValues h2 {
    font-size: 1.2rem;
  }

  #aboutPage .inFront img {
    max-width: 100%;
  }

  .largeOuterRight {
    position: inherit;
    right: unset;
  }

  .outerRight {
    position: inherit;
    right: unset;
  }

  .outerLeft {
    position: inherit;
    left: unset;
    margin-top: 1rem;
  }

  .m-all-round {
    border-radius: 20px !important;
  }

  .decoration {
    margin-left: -2rem;
  }

  .navbar-toggler {
    outline: none;
    border: none;
    color: #fff;
  }
  .navbar-toggler:focus {
    outline: none;
  }
  .navbar-toggler:hover {
    outline: none;
  }

  .btn-header {
    padding: 0.5rem;
  }

  .mmw-100 {
    max-width: 100%;
  }

  .absoluteHeaderTitle {
    margin-top: 1rem;
    position: inherit;
    padding: 1rem 3rem;
    border-radius: 20px;
    left: inherit;
    top: inherit;
  }
  .absoluteHeaderTitle h1, .absoluteHeaderTitle h2 {
    font-size: 2rem;
  }

  .smallDarkBtn, #chatControls #sendBtn {
    padding: 1rem 3rem;
  }

  #brodcaster_pic {
    top: -4rem;
    max-width: 7rem;
    left: 0;
    position: inherit;
    margin-bottom: -3rem;
  }

  .banner {
    min-height: 5rem;
  }

  .detailCamera .coverImage {
    width: 100%;
  }
  .detailCamera .darkCover {
    width: 100%;
  }

  input {
    max-width: 100%;
  }

  .plr-15r {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .playIcon {
    padding-bottom: 15%;
  }

  .darkBtn {
    padding: 1rem;
    font-size: 0.5rem;
  }

  .btnIcon {
    max-width: 3rem;
  }

  #dynamic_form1 input[type=email], #dynamic_form1 input[type=text], #dynamic_form1 textarea {
    max-width: 100%;
  }

  .absoluteVideoTitle {
    display: none;
  }

  .absoluteVideoTitleMobile {
    background-color: rgba(82, 89, 85, 0.7);
    text-align: left;
    padding: 1rem 1rem;
    border-radius: 20px;
  }
  .absoluteVideoTitleMobile h1 {
    color: #75BFA0;
    font-size: 2rem;
    font-family: "Nerko One", cursive;
  }
  .absoluteVideoTitleMobile h2 {
    color: #75BFA0;
    font-size: 2rem;
    font-family: "Nerko One", cursive;
  }
  .absoluteVideoTitleMobile h3 {
    color: white;
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-family: "Nerko One", cursive;
  }
  .absoluteVideoTitleMobile a {
    color: white;
    text-decoration: none;
  }

  #messageBox {
    position: absolute;
    left: 0;
    top: -3rem;
    height: 2rem;
    max-width: 100%;
  }

  .chatbox {
    display: inline;
    font-size: 0.7rem;
  }

  #overlayNest {
    position: absolute;
    top: 9rem;
    right: -1rem;
    width: 2rem;
  }
  #overlayNest img {
    max-width: 5rem;
  }
}
/*! 
 * ##Device = Most of the Smartphones Mobiles (Portrait)
 * ##Screen = B/w 321px to 479px */
@media (min-width: 321px) and (max-width: 767px) {
  #pubBottom {
    margin-top: 28rem;
  }

  .displayOnMobile {
    visibility: visible;
  }

  .hideOnMobile {
    visibility: hidden;
    display: none;
  }

  .mAround {
    display: flex;
    justify-content: space-around;
  }

  .darkBtn {
    padding: 1rem;
    font-size: 0.7rem;
  }

  .btnIcon {
    max-width: 3rem;
  }

  .mm-0 {
    margin: 0px;
  }

  .mmt-2 {
    margin-top: 2rem;
  }

  .mmt-1 {
    margin-top: 1rem;
  }

  .mmt-0 {
    margin-top: 0rem;
  }

  .mmb-0 {
    margin-bottom: 0rem;
  }

  .mml-2 {
    margin-left: 2rem;
  }

  .mmt-5 {
    margin-top: 5rem;
  }

  .mplr-1 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .mp-1 {
    padding: 1rem !important;
  }

  .mp-05 {
    padding: 0.5rem !important;
  }

  .mp-0 {
    padding: 0rem !important;
  }

  .m-text-center {
    text-align: center;
  }

  .coverImage {
    position: absolute;
    width: 100%;
    left: 0rem;
    top: 3rem;
  }
  .coverImage img {
    border-radius: 3rem;
    position: absolute;
    top: -2rem;
    left: 0rem;
    height: 250px;
    width: 100%;
  }

  .darkCover {
    height: 71%;
    width: 100%;
    left: 0rem;
  }

  #intro h2 {
    line-height: 1;
    font-size: 2rem;
  }
  #intro h3 {
    font-size: 2rem;
  }

  #introCamera {
    max-width: 100%;
    z-index: 2;
  }
  #introCamera h2 {
    font-size: 2rem;
  }
  #introCamera h3 {
    font-size: 1rem;
  }

  #txtCamera {
    padding: 1rem;
    z-index: 1;
    background: #fff;
  }
  #txtCamera h2 {
    font-size: 2rem;
    line-height: 2rem;
  }

  #txtNews {
    position: inherit;
    margin-top: 0;
    left: 0;
  }
  #txtNews h2 {
    font-size: 2rem;
  }
  #txtNews h3 {
    font-size: 1rem;
  }

  .largeOuterRight {
    position: inherit;
    right: unset;
  }

  .outerRight {
    position: inherit;
    right: unset;
  }

  .outerLeft {
    position: inherit;
    left: unset;
    margin-top: 1rem;
  }

  .m-all-round {
    border-radius: 20px !important;
  }

  .decoration {
    margin-left: -2rem;
  }

  .navbar-toggler {
    outline: none;
    border: none;
    color: #fff;
  }
  .navbar-toggler:focus {
    outline: none;
  }
  .navbar-toggler:hover {
    outline: none;
  }

  .btn-header {
    padding: 0.5rem;
  }

  .mmw-100 {
    max-width: 100%;
  }

  .absoluteHeaderTitle {
    margin-top: 1rem;
    position: inherit;
    padding: 1rem 3rem;
    border-radius: 20px;
    left: inherit;
    top: inherit;
  }
  .absoluteHeaderTitle h1, .absoluteHeaderTitle h2 {
    font-size: 3rem;
  }

  .smallDarkBtn, #chatControls #sendBtn {
    padding: 1rem 3rem;
  }

  #brodcaster_pic {
    top: -4rem;
    max-width: 7rem;
    left: 0;
    position: inherit;
    margin-bottom: -3rem;
  }

  .banner {
    min-height: 5rem;
  }

  .detailCamera .coverImage {
    width: 100%;
  }
  .detailCamera .darkCover {
    width: 100%;
  }

  input {
    max-width: 100%;
  }

  .plr-15r {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .playIcon {
    padding-bottom: 15%;
  }

  .absoluteVideoTitle {
    display: none;
  }

  .absoluteVideoTitleMobile {
    background-color: rgba(82, 89, 85, 0.7);
    text-align: left;
    padding: 1rem 1rem;
    border-radius: 20px;
  }
  .absoluteVideoTitleMobile h1 {
    color: #75BFA0;
    font-size: 2rem;
    font-family: "Nerko One", cursive;
  }
  .absoluteVideoTitleMobile h2 {
    color: #75BFA0;
    font-size: 2rem;
    font-family: "Nerko One", cursive;
  }
  .absoluteVideoTitleMobile h3 {
    color: white;
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-family: "Nerko One", cursive;
  }
  .absoluteVideoTitleMobile a {
    color: white;
    text-decoration: none;
  }

  #messageBox {
    position: absolute;
    left: 0;
    top: -3rem;
    height: 2rem;
    max-width: 100%;
  }

  .chatbox {
    display: inline;
    font-size: 0.7rem;
  }

  #overlayNest {
    position: absolute;
    right: -1rem;
    top: 9rem;
    width: 5rem;
  }
  #overlayNest img {
    max-width: 10rem;
  }
}
/*! 
 * ##Device = Most of the Smartphones Mobiles (landscape)
 * ##Screen = B/w 320px to 479px */
@media (max-width: 768px) and (orientation: landscape) {
  .plr-15r {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .mlr-15 {
    margin-left: 5rem;
  }

  .mrr-15 {
    margin-right: 5rem;
  }

  #overlayNest {
    position: absolute;
    right: 10rem;
    top: 9rem;
    width: 8rem;
  }
  #overlayNest img {
    max-width: 10rem;
  }
}
@media (max-width: 800px) and (orientation: portrait) {
  #overlayNest {
    position: absolute;
    right: 0rem;
    top: 17rem;
    width: 8rem;
  }
  #overlayNest img {
    max-width: 10rem;
  }

  #pubBottom img {
    height: 5rem;
  }
}
/*! 
 * ##Device = Tablets, Ipads (portrait)
 * ##Screen = B/w 768px to 1100px */
@media (min-width: 768px) and (max-width: 1100px) {
  #pubBottom {
    margin-top: 28rem;
  }

  .displayOnTablet {
    visibility: visible;
  }

  .hideOnTablet {
    visibility: hidden;
    display: none;
  }

  .mAround {
    display: flex;
    justify-content: space-around;
  }

  .mm-0 {
    margin: 0px;
  }

  .mmt-2 {
    margin-top: 2rem;
  }

  .mmt-1 {
    margin-top: 1rem;
  }

  .mmt-5 {
    margin-top: 5rem;
  }

  .mml-2 {
    margin-left: 2rem;
  }

  .mplr-1 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .mp-1 {
    padding: 1rem !important;
  }

  .mpl-5 {
    padding-left: 3rem !important;
  }

  .mpr-5 {
    padding-right: 3rem !important;
  }

  .mp-05 {
    padding: 0.5rem !important;
  }

  .mp-0 {
    padding: 0rem !important;
  }

  #intro h2 {
    line-height: 1;
  }

  #introCamera {
    max-width: 100%;
    z-index: 2;
  }

  #txtCamera {
    padding: 1rem;
    z-index: 1;
    background: #fff;
  }

  #txtNews {
    position: inherit;
    margin-top: 0;
    left: 0;
  }
  #txtNews h2 {
    font-size: 2rem;
  }

  .largeOuterRight {
    position: inherit;
    right: unset;
  }

  .outerRight {
    position: inherit;
    right: unset;
  }

  .outerLeft {
    position: inherit;
    left: unset;
    margin-top: 1rem;
  }

  .navbar-toggler {
    outline: none;
    border: none;
    color: #fff;
  }
  .navbar-toggler:focus {
    outline: none;
  }
  .navbar-toggler:focus:hover {
    outline: none;
  }

  .btn-header {
    padding: 0.5rem;
  }

  .mmw-100 {
    max-width: 100%;
  }

  .absoluteHeaderTitle {
    margin-top: 1rem;
    position: inherit;
    padding: 1rem 3rem;
    border-radius: 20px;
    left: inherit;
    top: inherit;
  }
  .absoluteHeaderTitle h1, .absoluteHeaderTitle h2 {
    font-size: 3rem;
  }

  .smallDarkBtn, #chatControls #sendBtn {
    padding: 1rem 3rem;
  }

  #messageBox {
    position: absolute;
    left: 0;
    top: -3rem;
    height: 2rem;
    max-width: 100%;
  }

  .chatbox {
    display: inline;
    font-size: 1rem;
  }

  #brodcaster_pic {
    top: -5rem;
    position: inherit;
    left: 2rem;
  }

  .banner {
    min-height: 5rem;
  }

  .fixedCameraHeight {
    height: 25rem;
  }

  .coverImage img {
    height: 25rem;
    left: 0;
    width: 100%;
  }

  .darkCover {
    height: 25rem;
  }

  .darkCover {
    left: 0;
    width: 100%;
  }

  .detailCamera .coverImage {
    width: 100%;
  }
  .detailCamera .coverImage img {
    height: 18rem;
    max-width: 95%;
  }
  .detailCamera .darkCover {
    width: 95%;
    height: 18rem;
  }
  .detailCamera .darkCover img {
    max-width: 50%;
    margin-top: 3rem;
  }

  .decoration {
    margin-left: -2rem;
  }

  input {
    max-width: 100%;
  }

  .navbar-toggler {
    outline: none;
    border: none;
    color: #fff;
  }
  .navbar-toggler:focus {
    outline: none;
  }
  .navbar-toggler:focus:hover {
    outline: none;
  }

  .broadcasterCoverImage {
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
  }

  .fixedBroadcasterCameraHeight {
    height: 18rem;
  }

  .accountCameras .coverImage img {
    height: 20rem;
  }

  .fixedAccountCameraHeight {
    height: 25rem;
  }

  .rightOnTablet {
    text-align: right;
  }

  .plr-15r {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .darkBtn {
    padding: 1rem 3rem;
  }

  .absoluteVideoTitle {
    left: -0.5rem;
    top: 2rem;
    padding: 1rem 2rem;
  }
  .absoluteVideoTitle h2 {
    font-size: 3rem;
  }
  .absoluteVideoTitle h3 {
    font-size: 2rem;
    line-height: 2rem;
  }

  .absoluteVideoTitleMobile {
    background-color: rgba(82, 89, 85, 0.7);
    text-align: left;
    padding: 1rem 2rem;
    border-radius: 20px;
  }
  .absoluteVideoTitleMobile h1 {
    color: #75BFA0;
    font-size: 3rem;
    font-family: "Nerko One", cursive;
  }
  .absoluteVideoTitleMobile h2 {
    color: #75BFA0;
    font-size: 3rem;
    font-family: "Nerko One", cursive;
  }
  .absoluteVideoTitleMobile h3 {
    color: white;
    font-size: 2.5rem;
    line-height: 2.5rem;
    font-family: "Nerko One", cursive;
  }
  .absoluteVideoTitleMobile a {
    color: white;
    text-decoration: none;
  }
}
/*! 
 * ##Device = Tablets, Ipads (landscape)
 * ##Screen = B/w 768px to 1100px */
@media (min-width: 768px) and (max-width: 1100px) and (orientation: landscape) {
  .displayOnTablet {
    visibility: visible !important;
    display: flex !important;
  }

  .hideOnTablet {
    visibility: hidden;
    display: none;
  }

  .outerLeft {
    left: 0rem;
  }

  .fixedCameraHeight {
    height: 20rem;
  }

  .coverImage img {
    height: 20rem;
  }

  .darkCover {
    height: 20rem;
  }

  .coverImage img {
    width: 100%;
  }

  .darkCover {
    width: 100%;
  }

  .detailCamera .coverImage {
    width: 100%;
  }
  .detailCamera .coverImage img {
    height: 300px;
  }
  .detailCamera .darkCover {
    width: 95%;
    height: 75%;
  }
  .detailCamera .darkCover img {
    max-width: 50% !important;
    margin-top: 1rem !important;
  }

  .decoration {
    margin-left: -3rem;
  }

  .largeOuterRight {
    position: inherit;
    right: 0rem;
  }

  .mlr-15 {
    margin-left: 5rem;
  }

  .mrr-15 {
    margin-right: 5rem;
  }

  .plr-10r {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .plr-15r {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .accountCameras .coverImage img {
    height: 15rem;
  }

  .fixedAccountCameraHeight {
    height: 15rem;
  }

  .playIcon {
    padding-bottom: 15%;
  }
}
/*! 
 * ##Device = Tablets, Ipads PRO (landscape)
 * ##Screen = B/w 990px to 1100px */
@media (min-width: 990px) and (max-width: 1100px) {
  #brodcaster_pic {
    left: 5%;
  }

  .detailCamera .coverImage {
    width: 100%;
  }
  .detailCamera .coverImage img {
    height: 200px;
  }
  .detailCamera .darkCover {
    width: 95%;
    height: 100%;
  }
  .detailCamera .darkCover img {
    max-width: 50%;
    margin-top: 3rem;
  }

  .fixedBroadcasterCameraHeight {
    height: 200px;
  }

  .largeOuterRight {
    position: inherit;
    right: -28rem;
  }

  .outerRight {
    position: inherit;
    right: unset;
  }

  .outerLeft {
    position: inherit;
    left: 2rem;
    margin-top: 1rem;
  }
}
/*!  
 * ##Device = Laptops, Desktops
 * ##Screen = B/w 1101px to 1280px */
@media (min-width: 1101px) and (max-width: 1280px) {
  .displayOnDesktop {
    visibility: visible;
  }

  .hideOnDesktop {
    visibility: hidden;
    display: none;
  }

  .plr-10r {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .pl-20r {
    padding-left: 2rem;
  }

  .plr-15r {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .w-20 {
    width: 25%;
  }

  .w-60 {
    width: 40%;
  }

  .absoluteVideoTitle {
    left: 0rem;
    top: 5rem;
    padding: 1rem 2rem;
  }
  .absoluteVideoTitle h2 {
    font-size: 4rem;
  }
  .absoluteVideoTitle h3 {
    font-size: 3rem;
    line-height: 3rem;
  }

  .absoluteHeaderTitle {
    left: 0rem;
  }

  #brodcaster_pic {
    left: -6rem;
  }

  .outerLeft {
    left: 0rem;
  }

  .largeOuterRight {
    right: -32rem;
  }

  #overlayNest {
    position: absolute;
    right: -2rem;
    top: 24rem;
    width: 10rem;
  }
  #overlayNest img {
    max-width: 10rem;
  }
}
@media (min-width: 1000px) and (max-width: 1100px) {
  #overlayNest {
    position: absolute;
    right: -2rem;
    top: 20rem;
    width: 10rem;
  }
  #overlayNest img {
    max-width: 10rem;
  }
}
@media (min-width: 1000px) and (max-width: 1100px) and (orientation: landscape) {
  #overlayNest {
    position: absolute;
    right: -2rem;
    top: 20rem;
    width: 7rem;
  }
  #overlayNest img {
    max-width: 10rem;
  }
}
@media (min-width: 800px) and (max-width: 1000px) {
  #overlayNest {
    position: absolute;
    right: 2rem;
    top: 20rem;
    width: 10rem;
  }
  #overlayNest img {
    max-width: 10rem;
  }

  #pubBottom img {
    height: 4rem;
  }
}
/*!  
 * ##Device = Laptops, Desktops
 * ##Screen = B/w 1281px to 1680px */
@media (min-width: 1281px) and (max-width: 1680px) {
  .displayOnDesktop {
    visibility: visible;
  }

  .hideOnDesktop {
    visibility: hidden;
    display: none;
  }

  .plr-10r {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .pl-20r {
    padding-left: 2rem;
  }

  .plr-15r {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .w-20 {
    width: 25%;
  }

  .w-60 {
    width: 40%;
  }

  .absoluteVideoTitle {
    left: 0rem;
    top: 5rem;
    padding: 1rem 2rem;
  }
  .absoluteVideoTitle h2 {
    font-size: 4rem;
  }
  .absoluteVideoTitle h3 {
    font-size: 3rem;
    line-height: 3rem;
  }

  .absoluteHeaderTitle {
    left: 0rem;
  }

  #brodcaster_pic {
    left: -6rem;
  }

  .outerLeft {
    left: -5rem;
  }

  .fixedBroadcasterCameraHeight {
    height: 200px;
  }

  .coverImage img {
    height: 200px;
  }

  .darkCover {
    height: 200px;
  }

  .darkCover .play {
    margin-right: 0rem;
  }

  .accountCameras .darkCover {
    height: 250px;
  }

  #overlayNest {
    position: absolute;
    right: 0rem;
    top: 28rem;
    width: 7rem;
  }
  #overlayNest img {
    max-width: 10rem;
  }
}
@media (min-width: 1300px) and (max-width: 1680px) {
  .coverImage img {
    height: 350px;
  }

  .darkCover {
    height: 350px;
  }
}
@media (min-width: 1681px) and (max-width: 1920px) {
  #overlayNest {
    position: absolute;
    right: 0rem;
    top: 29rem;
    width: 7rem;
  }
  #overlayNest img {
    max-width: 10rem;
  }
}
/*! 
 * ##Device = Desktops
 * ##Screen = 1681px to higher resolution desktops */
@media (min-width: 1681px) and (max-width: 4500px) {
  .displayOnDesktop {
    visibility: visible;
  }

  .hideOnDesktop {
    visibility: hidden;
    display: none;
  }
}
@media (min-width: 1921px) and (max-width: 4500px) {
  #overlayNest {
    position: absolute;
    right: 0rem;
    top: 32rem;
    width: 8rem;
  }
  #overlayNest img {
    max-width: 10rem;
  }
}
/*! 
 * ##Device = Desktops 4K and UP
 * ##Screen = 2401px to higher resolution desktops */
@media (min-width: 2401px) {
  .displayOnDesktop {
    visibility: visible;
  }

  .hideOnDesktop {
    visibility: hidden;
    display: none;
  }

  .broadcasterCoverImage {
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
  }

  .fixedBroadcasterCameraHeight {
    height: 200px;
  }

  .fixedCameraHeight {
    height: 35rem;
  }

  .coverImage img {
    height: 35rem;
  }

  .darkCover {
    height: 35rem;
  }

  .largeOuterRight {
    right: -60rem;
  }

  .darkCover .play {
    margin-right: 0rem;
  }

  .accountCameras .darkCover {
    height: 250px;
  }

  .contactCamera .coverImage img {
    height: 300px;
  }
  .contactCamera .darkCover {
    width: 94%;
    height: 54%;
  }
  .contactCamera .darkCover .playIcon {
    padding-top: 100%;
  }

  #overlayNest {
    position: absolute;
    right: 10rem;
    top: 29rem;
    width: 10rem;
  }
  #overlayNest img {
    max-width: 10rem;
  }
}