/*! 
  ##Device = Desktops
  ##Screen = 1681px to higher resolution desktops

@media (min-width: 1681px) and (max-width: 4500px)
    .displayOnDesktop
        visibility: visible
    .hideOnDesktop
        visibility: hidden
        display: none

@media (min-width: 1921px)  and (max-width: 4500px)
    #overlayNest
        position: absolute
        right: 0rem
        top: 32rem
        width: 8rem
        img
            max-width: 10rem