/*!  
 ##Device = Laptops, Desktops
 ##Screen = B/w 1101px to 1280px

@media (min-width: 1101px) and (max-width: 1280px)
    .displayOnDesktop
        visibility: visible
    .hideOnDesktop
        visibility: hidden
        display: none
    .plr-10r
        padding-left: 1rem!important
        padding-right: 1rem!important
    .pl-20r
        padding-left: 2rem
    .plr-15r
        padding-left: 10rem!important
        padding-right: 10rem!important
    .w-20
        width: 25%
    .w-60
        width: 40%
    .absoluteVideoTitle
        left: 0rem
        top: 5rem
        padding: 1rem 2rem
        h2
            font-size: 4rem
        h3
            font-size: 3rem
            line-height: 3rem
    .absoluteHeaderTitle
        left: 0rem
    #brodcaster_pic
        left: -6rem
    .outerLeft
        left: 0rem
    .largeOuterRight
        right: -32rem
    #overlayNest
        position: absolute
        right: -2rem
        top: 24rem
        width: 10rem
        img
            max-width: 10rem

@media (min-width: 1000px) and (max-width: 1100px)

    #overlayNest
        position: absolute
        right: -2rem
        top: 20rem
        width: 10rem
        img
            max-width: 10rem
@media (min-width: 1000px) and (max-width: 1100px) and (orientation: landscape)

    #overlayNest
        position: absolute
        right: -2rem
        top: 20rem
        width: 7rem
        img
            max-width: 10rem
@media (min-width: 800px) and (max-width: 1000px)

    #overlayNest
        position: absolute
        right: 2rem
        top: 20rem
        width: 10rem
        img
            max-width: 10rem
    #pubBottom
        img
            height: 4rem
