.btn-header
    background-color: white
    color: black!important
    border-radius: 10px
    padding: 5px 10px
    font-weight: 600

.darkBtn
    background-color: $darkgrey
    color: #fff!important
    padding: 1rem 12rem
    border-radius: 14px
    font-weight: 500
    font-size: 1rem
    justify-content: center
    text-transform: uppercase
    span
        vertical-align: middle
    &:hover
        text-decoration: none!important
.smallDarkBtn
    background-color: $darkgrey
    color: #fff!important
    padding: 1rem 5rem
    border-radius: 14px
    font-weight: 500
    font-size: 1rem
    justify-content: center
    text-transform: uppercase
    span
        vertical-align: middle
    &:hover
        text-decoration: none!important

.btnAddikpetDark
    margin-top: 0.5rem
    padding: 5px 10px
    background-color: $darkgrey
    color: #fff
    text-transform: uppercase
    border: none
    outline: none
    font-size: 1rem
    box-shadow: inset 0 -0.6em 0 -0.35em rgba($brand-primary,1)
    font-family: Nerko One,cursive
    &:hover
        color: $brand-primary
        text-decoration: none!important
        box-shadow: inset 0 -0.4em 0 -0.29em rgba($brand-primary,1)
.btnAddikpet
    margin-top: 0.5rem
    padding: 5px 10px
    background-color: $brand-primary
    color: #fff
    text-transform: uppercase
    border: none
    outline: none
    box-shadow: inset 0 -0.6em 0 -0.35em rgba($darkgrey,0.17)
    font-family: 'Nerko One', cursive
    &:hover
        box-shadow: inset 0 -0.4em 0 -0.29em rgba($darkgrey,0.17)
.btnSub
    padding: .3rem .5rem