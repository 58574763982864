.darkBg
    background-color: $dark
    color: white
.blueBg
    background-color: $brand-primary
    color: white
.darkBlueBg
    background-color: $brand-primary-dark
    color: white
.whiteBg
    background-color: #fff
.blueBord
    border: 4px solid $brand-primary
    border-radius: 20px
    z-index: -1
.white
    color: white