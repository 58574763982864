/*! 
 ##Device = Desktops 4K and UP
 ##Screen = 2401px to higher resolution desktops

@media (min-width: 2401px)
  .displayOnDesktop
    visibility: visible
  .hideOnDesktop
    visibility: hidden
    display: none
  .broadcasterCoverImage
    background-size: contain
    background-repeat: no-repeat
    background-position-x: center
  .fixedBroadcasterCameraHeight
    height: 200px
  .fixedCameraHeight
    height: 35rem
  .coverImage
    img
      height: 35rem
  .darkCover
    height: 35rem
  .largeOuterRight
    right: -60rem
  .darkCover
    .play
      margin-right: 0rem
  .accountCameras
    .darkCover
      height: 250px
  .contactCamera
    .coverImage
      img
        height: 300px
    .darkCover
      width: 94%
      height: 54%
      .playIcon
        padding-top: 100%
  #overlayNest
        position: absolute
        right: 10rem
        top: 29rem
        width: 10rem
        img
            max-width: 10rem
