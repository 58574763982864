/*! 
 ##Device = Most of the Small Smartphones Mobiles (Portrait)
 ##Screen = B/w 320px to 479px

@media (max-width: 320px)
    #pubBottom
        margin-top: 28rem
    .displayOnMobile
        visibility: visible
    .hideOnMobile
        visibility: hidden
        display: none
    .mAround
        display: flex
        justify-content: space-around
    .mm-0
        margin: 0px
    .mmt-2
        margin-top: 2rem
    .mmb-0
        margin-bottom: 0rem!important
    .mmt-0
        margin-top: 0rem!important
    .mmt-1
        margin-top: 1rem!important
    .mml-2
        margin-left: 2rem
    .mmt-5
        margin-top: 5rem
    .mplr-1
        padding-left: 1rem!important
        padding-right: 1rem!important
    .mp-1
        padding: 1rem!important
    .mp-05
        padding: 0.5rem!important
    .mp-0
        padding: 0rem!important
    .m-text-center
        text-align: center
    .coverImage
        position: absolute
        width: 100%
        left: 0rem
        top: 3rem
        img
            border-radius: 3rem
            position: absolute
            top: -2rem
            left: 0rem
            height: 15rem
            width: 100%
    .fixedCameraHeight
        height: 15rem
    .darkCover
        height: 71%
        width: 100%
        left: 0rem
    #intro
        h2
            line-height: 1
            font-size: 2rem
        h3
            font-size: 2rem
    #introCamera
        padding: 1.5rem
        max-width: 100%
        z-index: 2
        h2
            font-size: 2rem
        h3
            font-size: 1rem
    #txtCamera
        padding: 1rem
        z-index: 1
        background: #fff
        h2
            font-size: 2rem
            line-height: 2rem
    #txtNews
        position: inherit
        margin-top: 0
        left: 0
        h2
            font-size: 2rem
        h3
            font-size: 1rem
    #txtAbout h3, #txtMission h3, #txtValues h3
        font-size: 1.5rem
    #txtAbout h2, #txtMission h2, #txtValues h2
        font-size: 1.2rem
    #aboutPage
        .inFront
            img
                max-width: 100%

    .largeOuterRight
        position: inherit
        right: unset
    .outerRight
        position: inherit
        right: unset
    .outerLeft
        position: inherit
        left: unset
        margin-top: 1rem
    .m-all-round
        border-radius: 20px!important
    .decoration
        margin-left: -2rem

    .navbar-toggler
        outline: none
        border: none
        color: #fff
        &:focus
            outline: none
        &:hover
            outline: none
    .btn-header
        padding: 0.5rem
    .mmw-100
        max-width: 100%
    .absoluteHeaderTitle
        margin-top: 1rem
        position: inherit
        padding: 1rem 3rem
        border-radius: 20px
        left: inherit
        top: inherit
        h1,h2
            font-size: 2rem
    .smallDarkBtn
        padding: 1rem 3rem
    #brodcaster_pic
        top: -4rem
        max-width: 7rem
        left: 0
        position: inherit
        margin-bottom: -3rem
    .banner
        min-height: 5rem
    .detailCamera
        .coverImage
            width: 100%
        .darkCover
            width: 100%
    input
        max-width: 100%
    .plr-15r
        padding-left: 1rem!important
        padding-right: 1rem!important
    .playIcon
        padding-bottom: 15%
    .darkBtn
        padding: 1rem
        font-size: .5rem
    .btnIcon
        max-width: 3rem
    #dynamic_form1 input[type=email], #dynamic_form1 input[type=text], #dynamic_form1 textarea
        max-width: 100%
    .absoluteVideoTitle
        display: none
    .absoluteVideoTitleMobile
        background-color: rgba($darkgrey,0.7)
        text-align: left
        padding: 1rem 1rem
        border-radius: 20px
        h1
            color: $brand-primary
            font-size: 2rem
            font-family: 'Nerko One', cursive
        h2
            color: $brand-primary
            font-size: 2rem
            font-family: 'Nerko One', cursive
        h3
            color: white
            font-size: 1.5rem
            line-height: 1.5rem
            font-family: 'Nerko One', cursive
        a
            color: white
            text-decoration: none
    #messageBox
        position: absolute
        left: 0
        top: -3rem
        height: 2rem
        max-width: 100%
    .chatbox
        display: inline
        font-size: 0.7rem
    #overlayNest
        position: absolute
        top: 9rem
        right: -1rem
        width: 2rem
        img
            max-width: 5rem

/*! 
 ##Device = Most of the Smartphones Mobiles (Portrait)
 ##Screen = B/w 321px to 479px

@media (min-width: 321px) and (max-width: 767px)
    #pubBottom
        margin-top: 28rem
    .displayOnMobile
        visibility: visible
    .hideOnMobile
        visibility: hidden
        display: none
    .mAround
        display: flex
        justify-content: space-around
    .darkBtn
        padding: 1rem
        font-size: 0.7rem
    .btnIcon
        max-width: 3rem
    .mm-0
        margin: 0px
    .mmt-2
        margin-top: 2rem
    .mmt-1
        margin-top: 1rem
    .mmt-0
        margin-top: 0rem
    .mmb-0
        margin-bottom: 0rem
    .mml-2
        margin-left: 2rem
    .mmt-5
        margin-top: 5rem
    .mplr-1
        padding-left: 1rem!important
        padding-right: 1rem!important
    .mp-1
        padding: 1rem!important
    .mp-05
        padding: 0.5rem!important
    .mp-0
        padding: 0rem!important
    .m-text-center
        text-align: center
    .coverImage
        position: absolute
        width: 100%
        left: 0rem
        top: 3rem
        img
            border-radius: 3rem
            position: absolute
            top: -2rem
            left: 0rem
            height: 250px
            width: 100%
    .darkCover
        height: 71%
        width: 100%
        left: 0rem
    #intro
        h2
            line-height: 1
            font-size: 2rem
        h3
            font-size: 2rem
    #introCamera
        max-width: 100%
        z-index: 2
        h2
            font-size: 2rem
        h3
            font-size: 1rem
    #txtCamera
        padding: 1rem
        z-index: 1
        background: #fff
        h2
            font-size: 2rem
            line-height: 2rem
    #txtNews
        position: inherit
        margin-top: 0
        left: 0
        h2
            font-size: 2rem
        h3
            font-size: 1rem
    .largeOuterRight
        position: inherit
        right: unset
    .outerRight
        position: inherit
        right: unset
    .outerLeft
        position: inherit
        left: unset
        margin-top: 1rem
    .m-all-round
        border-radius: 20px!important
    .decoration
        margin-left: -2rem

    .navbar-toggler
        outline: none
        border: none
        color: #fff
        &:focus
            outline: none
        &:hover
            outline: none
    .btn-header
        padding: 0.5rem
    .mmw-100
        max-width: 100%
    .absoluteHeaderTitle
        margin-top: 1rem
        position: inherit
        padding: 1rem 3rem
        border-radius: 20px
        left: inherit
        top: inherit
        h1,h2
            font-size: 3rem
    .smallDarkBtn
        padding: 1rem 3rem
    #brodcaster_pic
        top: -4rem
        max-width: 7rem
        left: 0
        position: inherit
        margin-bottom: -3rem
    .banner
        min-height: 5rem
    .detailCamera
        .coverImage
            width: 100%
        .darkCover
            width: 100%
    input
        max-width: 100%
    .plr-15r
        padding-left: 1rem!important
        padding-right: 1rem!important
    .playIcon
        padding-bottom: 15%
    .absoluteVideoTitle
        display: none
    .absoluteVideoTitleMobile
        background-color: rgba($darkgrey,0.7)
        text-align: left
        padding: 1rem 1rem
        border-radius: 20px
        h1
            color: $brand-primary
            font-size: 2rem
            font-family: 'Nerko One', cursive
        h2
            color: $brand-primary
            font-size: 2rem
            font-family: 'Nerko One', cursive
        h3
            color: white
            font-size: 1.5rem
            line-height: 1.5rem
            font-family: 'Nerko One', cursive
        a
            color: white
            text-decoration: none
    #messageBox
        position: absolute
        left: 0
        top: -3rem
        height: 2rem
        max-width: 100%
    .chatbox
        display: inline
        font-size: 0.7rem

    #overlayNest
        position: absolute
        right: -1rem
        top: 9rem
        width: 5rem
        img
            max-width: 10rem
/*! 
 ##Device = Most of the Smartphones Mobiles (landscape)
 ##Screen = B/w 320px to 479px

@media (max-width: 768px) and (orientation: landscape)
    .plr-15r
        padding-left: 5rem!important
        padding-right: 5rem!important
    .mlr-15
        margin-left: 5rem
    .mrr-15
        margin-right: 5rem
    #overlayNest
        position: absolute
        right: 10rem
        top: 9rem
        width: 8rem
        img
            max-width: 10rem    

@media (max-width: 800px) and (orientation: portrait)
    #overlayNest
        position: absolute
        right: 0rem
        top: 17rem
        width: 8rem
        img
            max-width: 10rem
    #pubBottom
        img
            height: 5rem