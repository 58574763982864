@media (min-width: 1281px)
    .mw-50
        max-width: 50%
    .mw-20
        max-width: 20%
    .mw-30
        max-width: 30%
    .mw-40
        max-width: 40%
    .w-20
        width: 20%
    .w-25
        width: 25%
    .w-45
        width: 45%
    .w-60
        width: 60%
    .w-95
        width: 95%!important
    .p-0
        padding: 0px
    .pr-1
        padding: 1rem
    .pr-2
        padding: 2rem
    .mlr-5
        margin-left: 5rem
    .mtr-5
        margin-top: 5rem
    .mtr-10
        margin-top: 10rem
    .mtr-15
        margin-top: 15rem
    .mbr-5
        margin-bottom: 5rem
    .mbr-8
        margin-bottom: 8rem
    .mbr-10
        margin-bottom: 10rem
    .mlr-10
        margin-left: 10rem
    .mrr-5
        margin-right: 5rem
    .mrr-10
        margin-right: 10rem
    .mlr-15
        margin-left: 15rem
    .mrr-15
        margin-right: 15rem
    .mrr-20
        margin-right: 20rem
    .mrr-25
        margin-right: 25rem
    .mh-10
        min-height: 10rem
    .mh-7
        min-height: 7rem
    .pl-20r
        padding-left: 20rem
    .pl-25r
        padding-left: 25rem
    .plr-5r
        padding-left: 5rem!important
        padding-right: 5rem!important
    .plr-1r
        padding-left: 1rem!important
        padding-right: 1rem!important
    .plr-10r
        padding-left: 10rem!important
        padding-right: 10rem!important
    .plr-15r
        padding-left: 15rem!important
        padding-right: 15rem!important
    .p-header
        padding: .5rem 1rem
    .m-header
        margin: .5rem 1rem
@media (min-width: 1023px) and (max-width: 1280px)
    .mw-50
        max-width: 50%
    .mw-20
        max-width: 20%
    .mw-30
        max-width: 30%
    .mw-40
        max-width: 40%
    .w-20
        width: 20%
    .w-25
        width: 25%
    .w-45
        width: 45%
    .w-60
        width: 60%
    .w-95
        width: 95%!important
    .p-0
        padding: 0px
    .pr-1
        padding: 1rem
    .pr-2
        padding: 2rem
    .mlr-5
        margin-left: 5rem
    .mtr-5
        margin-top: 5rem
    .mtr-10
        margin-top: 5rem
    .mtr-15
        margin-top: 15rem
    .mbr-5
        margin-bottom: 5rem
    .mbr-8
        margin-bottom: 8rem
    .mbr-10
        margin-bottom: 5rem
    .mlr-10
        margin-left: 10rem
    .mrr-5
        margin-right: 5rem
    .mrr-10
        margin-right: 10rem
    .mrr-20
        margin-right: 20rem
    .mrr-25
        margin-right: 25rem
    .mh-10
        min-height: 10rem
    .mh-7
        min-height: 7rem
    .pl-20r
        padding-left: 20rem
    .pl-25r
        padding-left: 25rem
    .plr-5r
        padding-left: 5rem!important
        padding-right: 5rem!important
    .plr-1r
        padding-left: 1rem!important
        padding-right: 1rem!important
    .plr-10r
        padding-left: 10rem!important
        padding-right: 10rem!important
    .plr-15r
        padding-left: 15rem!important
        padding-right: 15rem!important
    .p-header
        padding: .5rem 1rem
    .m-header
        margin: .5rem 1rem
.w-15r
    width: 15rem
.fdc
    flex-direction: column
.jcsb
    justify-content: space-between
.round
    border-radius: 0px 0px 20px 20px
.roundCorner
    border-radius: 20px
.br-2r
    border-radius: 2rem
.br-3r
    border-radius: 3rem
.end
    display: flex
    margin-left: auto
.nobord
    border: none
.flexCollumnCenter
    display: flex
    justify-content: center
    flex-direction: column
.flexTextEnd
    display: flex
    flex-direction: column
    align-items: flex-end
.f-1
    font-size: 1rem
.l-0
    left: 0px!important
.f-w-normal
    font-weight: 200!important
#pubBottom
    .carousel-indicators
        display: none
#hauts
    .carousel-indicators
        display: none
#droits
    .carousel-indicators
        display: none