/** Light color-scheme **/
\:root
  --cc-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"
  --cc-font-size: 16px
  --cc-bg: #fff
  --cc-z-index: 2147483647
  --cc-text: #2b2b2b
  --cc-border-radius: .45rem
  --cc-btn-primary-bg: #75BFA0
  --cc-btn-primary-text: var(--cc-bg)
  --cc-btn-primary-hover-bg: #525955
  --cc-btn-primary-hover-text: var(--cc-btn-primary-text)
  --cc-btn-secondary-bg: #eaeff2
  --cc-btn-secondary-text: var(--cc-text)
  --cc-btn-secondary-hover-bg: #d8e0e6
  --cc-btn-secondary-hover-text: var(--cc-btn-secondary-text)
  --cc-btn-border-radius: 2em
  --cc-toggle-bg-off: #919ea6
  --cc-toggle-bg-on: var(--cc-btn-primary-bg)
  --cc-toggle-bg-readonly: #d5dee2
  --cc-toggle-knob-bg: #fff
  --cc-toggle-knob-icon-color: #ecf2fa
  --cc-block-text: var(--cc-text)
  --cc-cookie-category-block-bg: #F7F7F7
  --cc-cookie-category-block-bg-hover: #e9eff4
  --cc-section-border: #f1f3f5
  --cc-cookie-table-border: #e9edf2
  --cc-overlay-bg: rgb(4, 6, 8)
  --cc-overlay-opacity: .85
  --cc-consent-modal-box-shadow: 0 0.625rem 1.875rem rgba(2, 2, 3, 0.28)
  --cc-webkit-scrollbar-bg: #cfd5db
  --cc-webkit-scrollbar-bg-hover: #9199a0

button[data-cc="show-preferencesModal"]
  position: fixed
  z-index: 9999
  bottom: 0
  left: 2rem
  padding: 12px 18px
  border: 0
  border-radius: 8px 8px 0 0
  background-color: white
  cursor: pointer
  transform: translateY(30px)
  transition: transform 0.25s ease
  will-change: transform
  font-size: 14px
  font-weight: bold
  color: black
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.5)

button[data-cc="show-preferencesModal"]:hover
  transform: translateY(0)

button[data-cc="show-preferencesModal"]:focus
  outline: none
